$hero-main-height: 500px;
$hero-detail-open-hero-main-height: 300px;
$hero-detail-height: 250px;

$right-column-height: 780px;


@include media-medium {

    #content {
        height: auto;
    }

    #left,
    #left-top-grid
    #right,
    #right-top-grid{
        float: none;
        width: 100%;
    }

    #left,
    #left-top-grid,
    #left-bottom-grid {
        height: auto;
    }
        
    #right,
    #right-top-grid,
    #right-bottom-grid,
    #center-top-grid{
        height: $right-column-height;
        @include transform(none);
    }
    
    .bottom-grid-container{
        height: 100%;
    }
    
    .left-bottom-grid .container{
        width: unset;
    }
    
    #column-9{
        display: none;
    }

    .hero-background {
        height: $hero-main-height;
    }

    .hero-main, .hero-detail {
        float: none;
    }

    .hero-detail {
        position: absolute;
        top: auto;
        top: initial;
        right: auto;
        right: initial;
        height: $hero-detail-height;
        width: 100%;
    }

    .hero-main {
        height: $hero-main-height;
    }

    body.hero-detail-open {

        .hero-background {
            width: 100%;
            height: $hero-detail-open-hero-main-height + $hero-detail-height;
        }

        .hero-main {
            height: $hero-detail-open-hero-main-height;
        }

        #right,
        #right-top-grid,
        #right-bottom-grid {
            margin-top: $hero-detail-height;
            @include transform(none);
        }
    }
    
    #center-top-grid {
        height: auto;
    }
    .twenty-height {
        height: auto;
    }
    .eighty-height {
        height: auto;
    }

}
