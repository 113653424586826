$hero-main-height: 300px;
$hero-detail-open-hero-main-height: 250px;
$hero-detail-height: 250px;


@include media-small {

    .hero-background {
        height: $hero-main-height;
    }

    .hero-main {
        height: $hero-main-height;
    }

    body.hero-detail-open {

        .hero-background {
            height: $hero-detail-open-hero-main-height + $hero-detail-height;
        }

        .hero-main {
            height: $hero-detail-open-hero-main-height;
        }

        #right {
            margin-top: $hero-detail-height;
        }
    }

    .feature-tile-title {
        font-size: 22px;
    }
    
    .half-height {
        height: auto;
    }

    .full-height {
        height: auto !important;
    }

}
